export default async function (editor: any, opt: any = {}) {
	const { 
		componentName, 
		category,
		defaultRedirectMode, 
		defaultRedirectUrl, 
		defaultRedirectStatic, 
		defaultRedirectDate, 
		defaultRedirectOffset 
	} = opt;

  	const blockManager = editor.BlockManager;
	const domComponents = editor.DomComponents;

	const defaultType = domComponents.getType('default');
	const defaultModel = defaultType.model;
	// const defaultView = defaultType.view;


	blockManager.add(componentName, {
			label: 'Таймер с редиректом',
			category: category, 
			attributes: {class:''},
			content: `
	<div class="${componentName}">
		<div>
			<div class="timer__items">
				<div class="timer__item timer__days" data-title="дней">
					00
				</div>
				<div class="timer__item timer__hours" data-title="часов">
					00
				</div>
				<div class="timer__item timer__minutes" data-title="минут">
					00
				</div>
				<div class="timer__item timer__seconds" data-title="секунд">
					00
				</div>
			</div>
		</div>
	</div>
		`});


	const redirectModeName = 'redirectMode';
	const redirectUrlName = 'redirectUrl';
	const redirectOffsetName = 'redirectOffset';
	const redirectStaticName = 'redirectStatic';
	const redirectDateName = 'redirectDate';


	domComponents.addType(componentName, {
		model: defaultModel.extend({
		  	defaults: {
				...defaultModel.prototype.defaults,
				// droppable: ':not(form)',
				// draggable: ':not(form)',

				redirectMode: defaultRedirectMode,
				redirectUrl: defaultRedirectUrl,
				redirectOffset: defaultRedirectOffset,
				redirectStatic: defaultRedirectStatic,
				redirectDate: defaultRedirectDate,

				traits: [
					{
						type: 'checkbox',
						label: "Редирект",
						changeProp: 1,
						name: redirectModeName,
					},
					{
						type: 'text',
						label: "URL",
						changeProp: 1,
						name: redirectUrlName,
					},
					{
						type: 'number',
						label: "Обратный отсчёт (сек)",
						changeProp: 1,
						name: redirectOffsetName,
						min: 0,
					},
					{
						type: 'checkbox',
						label: "В фиксированную дату",
						changeProp: 1,
						name: redirectStaticName,
					},
					{
						type: 'datetime-local',
						label: "Дата редиректа",
						changeProp: 1,
						name: redirectDateName,
					},
				],

				/*
				data-redirect="false"
				data-url="https://yandex.ru"
				data-static="true"
				data-date="1631706703000"
				data-offset="172800000"
				*/
			
				script: function () {},
			},
	
		  	async init() {
				// handle changing attrs in traits
				this.listenTo(this, `change:${redirectModeName}`, () => {
					this.attributes.redirect = this.redirectModeName;
					this.addAttributes({'data-redirect': this.attributes.redirectMode.toString()});
				});
				this.listenTo(this, `change:${redirectStaticName}`, () => {
					this.attributes.static = this.redirectStaticName;
					this.addAttributes({'data-static': this.attributes.redirectStatic.toString()});
				});
				this.listenTo(this, `change:${redirectUrlName}`, () => {
					this.attributes.url = this.redirectUrlName;
					this.addAttributes({'data-url': this.attributes.redirectUrl});
				});
				this.listenTo(this, `change:${redirectOffsetName}`, () => {
					debugger;
					this.addAttributes({'data-offset': this.attributes.redirectOffset * 1000});
				});
				this.listenTo(this, `change:${redirectDateName}`, () => {
					this.attributes.date = (new Date(this.redirectDateName)).getTime();
					this.addAttributes({'data-date': (new Date(this.attributes.redirectDate)).getTime()});
				});
		  	},
	
		}, {
		  	isComponent(el: HTMLElement) {
				if(el.tagName === 'DIV' && el.className.indexOf(componentName) > -1) {
					return {type: componentName};
				}
		  	},
		}),

	});

}