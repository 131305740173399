const classNames = [

	'careerum-en-header',
    'careerum-en-header-tablet',
    'careerum-en-header-mobile',

    'careerum-en-bootcamp-main',

    'careerum-en-bootcamp-before',
    'careerum-en-bootcamp-before-tablet',
    'careerum-en-bootcamp-before-mobile',

    'careerum-en-bootcamp-clients',
    'careerum-en-bootcamp-clients-tablet',
    'careerum-en-bootcamp-clients-mobile',

    'careerum-en-bootcamp-advantages',
    'careerum-en-bootcamp-advantages-tablet',
    'careerum-en-bootcamp-advantages-mobile',

    'careerum-en-bootcamp-work',
    'careerum-en-bootcamp-work-tablet',
    'careerum-en-bootcamp-work-mobile',

    'careerum-en-bootcamp-author',
    'careerum-en-bootcamp-author-tablet',
    'careerum-en-bootcamp-author-mobile',

    'careerum-en-bootcamp-curriculum',
    'careerum-en-bootcamp-curriculum-tablet',
    'careerum-en-bootcamp-curriculum-mobile',

    'careerum-en-bootcamp-bonus',
    'careerum-en-bootcamp-bonus-tablet',
    'careerum-en-bootcamp-bonus-mobile',

    'careerum-en-bootcamp-reviews',
    'careerum-en-bootcamp-reviews-tablet',
    'careerum-en-bootcamp-reviews-mobile',

    'careerum-en-bootcamp-tariffs',
    'careerum-en-bootcamp-tariffs-tablet',
    'careerum-en-bootcamp-tariffs-mobile',

    'tariffs-items-wrapper',

    'careerum-en-bootcamp-faq',
    'careerum-en-bootcamp-faq-tablet',
    'careerum-en-bootcamp-faq-mobile',

    'careerum-en-bootcamp-banner',
    'careerum-en-bootcamp-banner-tablet',
    'careerum-en-bootcamp-banner-mobile',

    'careerum-en-bootcamp-guide',
    'careerum-en-bootcamp-guide-tablet',
    'careerum-en-bootcamp-guide-mobile',

    'careerum-en-bootcamp-timer-banner',
    'careerum-en-bootcamp-timer-banner-tablet',
    'careerum-en-bootcamp-timer-banner-mobile',

    'careerum-en-bootcamp-newsletter',
    'careerum-en-bootcamp-newsletter-tablet',
    'careerum-en-bootcamp-newsletter-mobile',

    'careerum-en-bootcamp-bottom-banner',
    'careerum-en-bootcamp-bottom-banner-tablet',
    'careerum-en-bootcamp-bottom-banner-mobile',
	
    'careerum-en-footer',
    'careerum-en-footer-tablet',
    'careerum-en-footer-mobile',
    
    'careerum-bootcamp-popup-wrapper',
    'careerum-bootcamp-popup-block',
    'careerum-bootcamp-subscribe',
    'careerum-bootcamp-subscribe2',
    'careerum-bootcamp-thanks-popup',
    'careerum-popup',

];

export default classNames;