export default function(editor: any, opt: any = {}) {
	const domc = editor.DomComponents;
    let componentName: string;
    let componentClass: string;
    let droppableWrapperSelector = '[data-gjs-type="wrapper"]';
    const traits = { 
        traits: [
            {
                name: 'id'
            },
            {
                label: 'Не обновлять',
                name: 'universal-block-not-update',
                type: 'checkbox'
            }
        ]
    };

    // universal and self-updatable components as header, footer and tariff block


    // Example
    // componentName = 'careerum-header-2';
    // domc.addType(componentName, {
    //     isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
    //     model: {
    //         defaults: {
    //             ...traits,
    //             tagName: 'div',
    //             classes: componentName,
    //             droppable: droppableWrapperSelector,
    //             draggable: droppableWrapperSelector,
    //             attributes: { 
    //                 version: "1.20",
    //             },
    //             components: `
    //             <div>
    //                 <div class="careerum-header__logo">
    //                     <a href="/ru-ru">
    //                         <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857387586.svg" alt="">
    //                     </a>
    //                 </div>
    //                 <div class="careerum-header__menu">
    //                     <a href="/ru-ru/club" class="careerum-header__club">Careerum.Club</a>
    //                     <a href="/ru-ru/club/gift-certificate" class="careerum-header__coaching">Подарочные сертификаты</a>
    //                     <a href="/ru-ru/expert-consultations" class="careerum-header__consultations">Консультации</a>
    //                     <a href="/ru-ru/success-stories" class="careerum-header__stories">Результаты участников</a>
    //                     <a href="/ru-ru/team" class="careerum-header__team">О нас</a>
    //                     <a href="#" class="careerum-header__blog hidden">Блог</a>
    //                     <a href="#" class="careerum-header__faq hidden">FAQ</a>
    //                     <a href="#" class="careerum-header__vacancies hidden">Вакансии</a>
    //                 </div>
    //                 <div class="careerum-header__dropdown">
    //                     <div>
    //                         <input type="checkbox" id="careerum-enter-input">
    //                         <label for="careerum-enter-input">Войти</label>
    //                         <div>
    //                             <a href="https://learn.careerum.com/">Образовательная платформа</a>
    //                             <a href="https://network.careerum.ru/">Платформа для нетворкинга</a>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             `,
    //         }
    //     }
    // });

    // componentName = 'careerum-header-2-tablet';
    // domc.addType(componentName, {
    //     isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
    //     model: {
    //         defaults: {
    //             ...traits,
    //             tagName: 'div',
    //             classes: componentName,
    //             droppable: droppableWrapperSelector,
    //             draggable: droppableWrapperSelector,
    //             attributes: { 
    //                 version: "1.20",
    //             },
    //             components: `
    //             <div>
    //                 <div>
    //                     <input type="checkbox" name="" id="careerum-header-check-tablet">
    //                     <label for="careerum-header-check-tablet"></label>
    //                     <div>
    //                         <div>
    //                             <a href="/ru-ru/club">Careerum.Club</a>
    //                             <a href="/ru-ru/club/gift-certificate">Подарочные сертификаты</a>
    //                             <a href="/ru-ru/expert-consultations">Консультации</a>
    //                             <a href="/ru-ru/success-stories">Результаты участников</a>
    //                             <a href="/ru-ru/team" class="careerum-header__team">О нас</a>
    //                             <a href="#" class="hidden">Блог</a>
    //                             <a href="#" class="hidden">FAQ</a>
    //                             <a href="#" class="hidden">Вакансии</a>
    //                         </div>
    //                         <div>
    //                             <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857430505.svg" alt="">
    //                         </div>
    //                         <div>
    //                             <a href="https://learn.careerum.com/">Войти на образовательную<br> платформу</a>
    //                             <a href="https://network.careerum.ru/">Войти на платформу<br> для нетворкинга</a>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div class="careerum-header__logo">
    //                     <a href="/ru-ru">
    //                         <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857454319.svg" alt="">
    //                     </a>
    //                 </div>
    //             </div>
    //             `,
    //         }
    //     }
    // });

    // componentName = 'careerum-header-2-mobile';
    // domc.addType(componentName, {
    //     isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
    //     model: {
    //         defaults: {
    //             ...traits,
    //             tagName: 'div',
    //             classes: componentName,
    //             droppable: droppableWrapperSelector,
    //             draggable: droppableWrapperSelector,
    //             attributes: { 
    //                 version: "1.20",
    //             },
    //             components: `
    //             <div>
    //                 <div style="">
    //                     <input type="checkbox" name="" id="careerum-header-check-mobile">
    //                     <label for="careerum-header-check-mobile"></label>
    //                     <div>
    //                         <div>
    //                             <a href="/ru-ru/club">Careerum.Club</a>
    //                             <a href="/ru-ru/club/gift-certificate">Подарочные сертификаты</a>
    //                             <a href="/ru-ru/expert-consultations">Консультации</a>
    //                             <a href="/ru-ru/success-stories">Результаты участников</a>
    //                             <a href="/ru-ru/team">О нас</a>
    //                             <a href="#" class="hidden">Блог</a>
    //                             <a href="#" class="hidden">FAQ</a>
    //                             <a href="#" class="hidden">Вакансии</a>
    //                         </div>
    //                         <div>
    //                             <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857477377.svg" alt="">
    //                         </div>
    //                         <div>
    //                             <a href="https://learn.careerum.com/">Войти на образовательную<br> платформу</a>
    //                             <a href="https://network.careerum.ru/">Войти на платформу<br> для нетворкинга</a>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div>
    //                     <a href="/ru-ru">
    //                         <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857454319.svg" alt="">
    //                     </a>
    //                 </div>
    //             </div>
    //             `,
    //         }
    //     }
    // });


}
