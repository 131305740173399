import grapesjs from 'grapesjs';
import initComponents from './components';
import careerumComponents from './components-careerum';
import loadBlocks from './blocks-careerum';

export default grapesjs.plugins.add('gjs-blocks-careerum', (editor: any, opts: any = {}) => {
  const config = {
    category: 'Careerum',
    ...opts
  };

  // Add components
  const addTypeFromClassName = initComponents(editor, config);
  addTypeFromClassName(careerumComponents);

  // Add blocks
  loadBlocks(editor, config);


  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
	const defaultModel = defaultType.model;

  const defaults = Object.assign({}, defaultModel.prototype.defaults, {
		traits: [
			{
				name: 'id'
			},
			{
				label: 'Название лейбла',
				name: 'data-waytarget',
				type: 'data-waytarget'
			},
      {
        label: 'Selector ID',
        name: 'data-waytarget-id',
        type: 'text'
      }
		],
		attributes: {
			'data-waytarget': ''
		}
	});

  const classes = ["tyles-popular-items", "tyles-tyle-items"];

  classes.forEach(function (className) {
    domc.addType(className, {
      model: defaultModel.extend({
        defaults: {
        ...defaults,
        }
      }, {
        isComponent(el: HTMLElement) {
          if(el.tagName === 'DIV' && el.classList.contains(className)){
            return {type: className};
          }
        },
      }),
    });
  });


  // Add attribute for header with language selection
  const defaults_lang = Object.assign({}, defaultModel.prototype.defaults, {
		traits: [
			{
				label: 'Регион',
				name: 'data-language',
				type: 'select',
        options: [ // Array of options
          { id: 'off', name: 'Off'},
          { id: 'ru', name: 'Ru'},
          { id: 'en', name: 'En'},
        ]
			}
		],
		attributes: {
			'data-language': 'off'
		}
	});

  const classes_lang = ["careerum-nav-with-region","careerum-nav-with-region-tablet","careerum-nav-with-region-mobile"];

  classes_lang.forEach(function (className) {
    domc.addType(className, {
      model: defaultModel.extend({
        defaults: {
        ...defaults_lang,
        }
      }, {
        isComponent(el: HTMLElement) {
          if(el.tagName === 'DIV' && el.classList.contains(className)){
            return {type: className};
          }
        },
      }),
    });
  });


  // Define new Trait type
  editor.TraitManager.addType('js-hide-script', {
    events:{
      'keyup': 'onChange',  // trigger parent onChange method on keyup
    },

    /**
    * Returns the input element
    * @return {HTMLElement}
    */
    getInputEl: function() {
      if(!this.inputEl) {
        var input = document.createElement('textarea');
        input.value = this.target.get('js-hide-script') || '';
        this.inputEl = input;
      }
      return this.inputEl;
    },

    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set('js-hide-script', newVal);
      this.target.attributes.attributes['js-hide-script'] = newVal;
    }
  });


  // Define new Trait type
  editor.TraitManager.addType('hide-by-geo', {
    // events:{
    //   'keyup': 'onChange',  // trigger parent onChange method on keyup
    // },

    /**
    * Returns the input element
    * @return {HTMLElement}
    */
    getInputEl: function() {
      if(!this.inputEl) {
        var input = document.createElement('textarea');
        input.value = this.target.get('hide-by-geo') || '';
        this.inputEl = input;
      }
      return this.inputEl;
    },

    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set('hide-by-geo', newVal);
      this.target.attributes.attributes['hide-by-geo'] = newVal;
    }
  });

  // add visible/hidden property to all elements
  domc.getTypes().map((type: any) => {
    const typeID = type.id;
    const defaultAttrs = domc.getType(typeID).model.prototype.defaults.attributes;

    domc.addType(typeID, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(typeID).model.prototype.defaults.traits,
            {
              label: 'JS для скрытия',
              name: 'js_script_hide_show',
              type: 'js-hide-script',
              changeProp: 1,
            },
            {
              label: 'Скрывать по гео',
              name: 'hide-by-geo',
              type: 'select',
              options: [ // Array of options
                { id: 'unset', name: 'нет'},
                { id: 'russia', name: 'Россия'},
                { id: 'not_russia', name: 'Не Россия'},
              ]
            }
          ],
          attributes: {
            ...defaultAttrs,
            'js-hide-script': '', 
            'hide-by-geo': defaultAttrs['hide-by-geo'] || 'unset', 
            required: false
          }
        }
      }

    })
  });

});
