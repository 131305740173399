export default async function (editor: any, opt: any = {}) {
	const { componentName, endTimeTraitName, category, defaultEndTime } = opt;

  const blockManager = editor.BlockManager;
	const domComponents = editor.DomComponents;

	const defaultType = domComponents.getType('default');
	const defaultModel = defaultType.model;
	// const defaultView = defaultType.view;

	blockManager.add(componentName, {
			label: 'Обратный таймер',
			category: category, 
			attributes: {class:''},
			content: `
		<div class="careerum-timer">
			<div>
					<h2>До окончания продаж осталось</h2>
					<div id="countdown">
							<div class="timer-container days">
					
									<!-- The actual progress bar SVG -->
									<svg viewBox="0 0 100 100">
											<!-- Trail path -->
											<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
							
											<!-- Actual progress bar path -->
											<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
									</svg>
							
									<!-- Text element created when text option is set -->
									<p class="days-text"></p>
									<span class="sub">дней</span>
							</div>
							<div class="timer-container hours">
					
									<!-- The actual progress bar SVG -->
									<svg viewBox="0 0 100 100">
											<!-- Trail path -->
											<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
							
											<!-- Actual progress bar path -->
											<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
									</svg>
							
									<!-- Text element created when text option is set -->
									<p class="hours-text"></p>
									<span class="sub">часов</span>
							</div>
							<div class="timer-container mins">
					
									<!-- The actual progress bar SVG -->
									<svg viewBox="0 0 100 100">
											<!-- Trail path -->
											<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
							
											<!-- Actual progress bar path -->
											<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
									</svg>
							
									<!-- Text element created when text option is set -->
									<p class="mins-text"></p>
									<span class="sub">минут</span>
							</div>
							<div class="timer-container secs">
					
									<!-- The actual progress bar SVG -->
									<svg viewBox="0 0 100 100">
											<!-- Trail path -->
											<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
							
											<!-- Actual progress bar path -->
											<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
									</svg>
							
									<!-- Text element created when text option is set -->
									<p class="secs-text"></p>
									<span class="sub">секунд</span>
							</div>
					</div>
			</div>
	</div>
		`});

	blockManager.add(`${componentName}-tablet`, {
		label: 'Обратный таймер (tablet)',
		category: category, 
		attributes: {class:''},
		content: `
	<div class="careerum-timer-tablet">
		<div>
				<h2>До окончания продаж осталось</h2>
				<div id="countdown">
						<div class="timer-container days">
				
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
										<!-- Trail path -->
										<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
						
										<!-- Actual progress bar path -->
										<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="days-text"></p>
								<span class="sub">дней</span>
						</div>
						<div class="timer-container hours">
				
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
										<!-- Trail path -->
										<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
						
										<!-- Actual progress bar path -->
										<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="hours-text"></p>
								<span class="sub">часов</span>
						</div>
						<div class="timer-container mins">
				
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
										<!-- Trail path -->
										<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
						
										<!-- Actual progress bar path -->
										<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="mins-text"></p>
								<span class="sub">минут</span>
						</div>
						<div class="timer-container secs">
				
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
										<!-- Trail path -->
										<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
						
										<!-- Actual progress bar path -->
										<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="secs-text"></p>
								<span class="sub">секунд</span>
						</div>
				</div>
			</div>
		</div>
	`});


	blockManager.add(`${componentName}-mobile`, {
		label: 'Обратный таймер (mobile)',
		category: category, 
		attributes: {class:''},
		content: `
			<div class="careerum-timer-mobile">
        <div>
					<h2>До окончания продаж осталось</h2>
					<div id="countdown">
						<div>
							<div class="timer-container days">
					
									<!-- The actual progress bar SVG -->
									<svg viewBox="0 0 100 100">
											<!-- Trail path -->
											<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
							
											<!-- Actual progress bar path -->
											<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
									</svg>
							
									<!-- Text element created when text option is set -->
									<p class="days-text"></p>
									<span class="sub">дней</span>
							</div>
						</div>
						<div>
							<div class="timer-container hours">
					
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
									<!-- Trail path -->
									<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
					
									<!-- Actual progress bar path -->
									<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="hours-text"></p>
								<span class="sub">часов</span>
							</div>
						</div>
						<div>
							<div class="timer-container mins">
				
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
									<!-- Trail path -->
									<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
					
									<!-- Actual progress bar path -->
									<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="mins-text"></p>
								<span class="sub">минут</span>
							</div>
						</div>
						<div>
							<div class="timer-container secs">
				
								<!-- The actual progress bar SVG -->
								<svg viewBox="0 0 100 100">
										<!-- Trail path -->
										<path d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97" stroke="#ddd" stroke-width="2" fill-opacity="0"></path>
						
										<!-- Actual progress bar path -->
										<path d="M 50,51.5 m 0,-48.5 a 47,47 0 1 1 0,94 a 46.5,46.5 0 1 1 0,-94" stroke="#FA744F" stroke-width="5" stroke-linecap="round" fill-opacity="0" style="stroke-dashoffset: 0px;" class="days-progress"></path>
								</svg>
						
								<!-- Text element created when text option is set -->
								<p class="secs-text"></p>
								<span class="sub">секунд</span>
							</div>
						</div>
					</div>
			</div>
    </div>
	`});


	domComponents.addType(componentName, {
		model: defaultModel.extend({
		  defaults: {
				...defaultModel.prototype.defaults,
				// droppable: ':not(form)',
				// draggable: ':not(form)',
				endTimeTraitName: defaultEndTime,
				traits: [{
					type: 'datetime-local',
					label: "Конец отсчёта",
					name: endTimeTraitName,
					options: []
				}],
				script: function () {},
		  },
	
		  async init() {
				// handle changing endTime in traits
				this.listenTo(this, `change:${endTimeTraitName}`, this.handleEndTimeChange);
		  },

			async handleEndTimeChange() {
				this.attributes.endTime = this.endTime;
		  },
	
		}, {
		  isComponent(el: HTMLElement) {
				if(el.tagName === 'DIV' && el.className.indexOf(componentName) > -1) {
					return {type: componentName};
				}
		  },
		}),

	});

}