import grapesjs from 'grapesjs';
import installTimer from './careerum-redirect-timer';

export default grapesjs.plugins.add('gjs-careerum-redirect-timer', async (editor: any, opts: any = {}) => {
  const config = {
    category: 'Extra',
    blockCategory: 'Careerum',
    componentName: 'careerum-redirect-timer',
    blockComponentName: 'block-careerum-redirect-timer',
    defaultRedirectMode: false,
    defaultRedirectUrl: 'https://google.com',
    defaultRedirectStatic: true,
    defaultRedirectDate: '1631706703000',
    defaultRedirectOffset: '172800000',
    ...opts
  };

  installTimer(editor, config);
});
