import axios, { AxiosResponse } from 'axios';

export const apiDomain = 'https://backend.careerum.com';
// export const apiDomain = 'http://localhost:3000';

export const apiBase = apiDomain + '/api/v1/';

export const xAPIHeaders = {
    'X-API-Key': "dcm_secret"
};

const requestHeaders = {
    withCredentials: true,
    headers: xAPIHeaders
}

const UPLOAD_ASSETS_URL = `${apiBase}assets/upload/`;
const GET_ASSETS_URL = `${apiBase}assets/`;

export class AssetsService {
    private static readonly getAssetsUrl = GET_ASSETS_URL;
    private static readonly uploadAssetsUrl = UPLOAD_ASSETS_URL;
    private static readonly uploadHeaders = Object.assign({"Content-Type": "multipart/form-data"}, xAPIHeaders);

    public static getAssetsList(): Promise<AxiosResponse<Array<string>>> {
        return axios.get(this.getAssetsUrl, requestHeaders);
    }

    public static uploadAssets(formData: FormData): Promise<AxiosResponse<Array<string>>> {
        return axios.post(this.uploadAssetsUrl, formData,
            {
                withCredentials: true,
                headers: this.uploadHeaders
            }
        );
    }
}


const PUBLISH_PAGE_URL = `${apiBase}pages/publish/`;

export class PagesService {
    private static readonly publishPageUrl = PUBLISH_PAGE_URL;

    public static publishPage(pageID: number): Promise<AxiosResponse<any>> {
        return axios.get(this.publishPageUrl + pageID, requestHeaders);
    }
}



const TRACK_REQUEST_URL = `${apiBase}sessions/track_request/`;

export class SessionService {
    private static readonly trackRequestUrl = TRACK_REQUEST_URL;

    public static trackRequest(url: string, referer: string): Promise<AxiosResponse<any>> {
        return axios.post(this.trackRequestUrl, {
            url: url,
            referer: referer
        }, requestHeaders);
    }
}



const GET_FORM_URL = `${apiBase}forms/`;
const CREATE_FORM_URL = `${apiBase}forms/create/`;
const UPDATE_FORM_URL = `${apiBase}forms/update/`;
const REMOVE_FORM_URL = `${apiBase}forms/delete/?id=`;
export const HANDLE_FORM_URL = `${apiBase}forms/handle/`;

export interface IForm {
    id?: number
    title: string | null
    pageID: number
    fields: any[]
    handlers: any[]
    offerID: number | null
}

export class FormService {
    private static readonly getFormUrl = GET_FORM_URL;
    private static readonly createFormUrl = CREATE_FORM_URL;
    private static readonly updateFormUrl = UPDATE_FORM_URL;
    private static readonly removeFormUrl = REMOVE_FORM_URL;
    private static readonly handleFormUrl = HANDLE_FORM_URL;


    public static getForm(formID: number): Promise<AxiosResponse<IForm>> {
        return axios.get(this.getFormUrl + formID, requestHeaders);
    }

    public static createForm(form: IForm): Promise<AxiosResponse<IForm>> {
        return axios.post(this.createFormUrl, form, requestHeaders);
    }

    public static updateForm(formID: number, form: IForm): Promise<AxiosResponse<IForm>> {
        return axios.post(this.updateFormUrl + formID, form, requestHeaders);
    }

    public static removeForm(formID: number): Promise<AxiosResponse<boolean>> {
        return axios.get(this.removeFormUrl + formID, requestHeaders);
    }

    public static handleForm(formID: number, formFields: any): Promise<AxiosResponse<any>> {
        return axios.post(this.handleFormUrl + formID, formFields, requestHeaders);
    }
}


export const GET_STYLE_TEMPLATE_URL = `${apiBase}style_templates/content/`;
/*
export class StyleTemplateService {
    private static readonly getStyleTemplateUrl = GET_STYLE_TEMPLATE_URL;
    private static readonly xAPIHeaders = xAPIHeaders;

    public static getStyleTemplate(styleTemplateID: number): Promise<AxiosResponse<any>> {
        return axios.get(this.getStyleTemplateUrl + styleTemplateID, {
            withCredentials: true,
            headers: xAPIHeaders
        });
    }

}
*/
