import grapesjs from 'grapesjs';
import installTimer from './careerum-timer';

export default grapesjs.plugins.add('gjs-careerum-timer', async (editor: any, opts: any = {}) => {
  const config = {
    category: 'Extra',
    componentName: 'careerum-timer',
    endTimeTraitName: 'endTime',
    defaultEndTime: 'July 31 2021 00:00:00 GMT+0300',
    ...opts
  };

  installTimer(editor, config);
});
