export default function(editor: any, opt: any = {}) {
	const domc = editor.DomComponents;
	const defaultType = domc.getType('default');
	const defaultModel = defaultType.model;

	const defaults = Object.assign({}, defaultModel.prototype.defaults, {
		traits: [
			{
				name: 'id'
			},
			{
				label: 'Якорь',
				name: 'custom-link-anchor',
				type: 'custom-anchor'
			}
		],
		attributes: {
			'custom-anchor': ''
		}
	});

	// const injectableComponents = [
	// 	'carrerum-question-with-social',
	// 	'carrerum-question-with-social-tablet',
	// 	'carrerum-question-with-social-mobile',

	// 	'careerum-registration-form',
	// 	'careerum-registration-form-tablet',
	// 	'careerum-registration-form-mobile',
	// ];

	// const droppableSelector = '[data-gjs-type="wrapper"]';
	// const droppableExcludeClasses = [
	// 	'careerum-getcourse-email-form',
	// 	'careerum-getcourse-email-form-tablet',
	// 	'careerum-getcourse-email-form-mobile',
	// 	'careerum-getcourse-email-form-2-fields',
	// 	'careerum-getcourse-email-form-2-fields-tablet',
	// 	'careerum-getcourse-email-form-2-fields-mobile',
	// ];

	const addTypeFromClassName = (className: string | string[]) => {
		if (Array.isArray(className)) {
			className.forEach(addTypeFromClassName);
			return;
		}

		// const droppable = injectableComponents.indexOf(className) == -1 ? droppableSelector : '*';
		let newDefaults = {
			...defaults,
		};

		// if (droppableExcludeClasses.indexOf(className) === -1) {
		// 	newDefaults["droppable"] = droppable;
		// 	newDefaults["draggable"] = droppable;
		// }

		domc.addType(className, {
			model: defaultModel.extend({
				defaults: newDefaults
			}, {
				isComponent(el: HTMLElement) {
					if(el.tagName === 'DIV' && el.classList.contains(className)){
						return {type: className};
					}
				},
			}),
		});
	};

	return addTypeFromClassName;
}