import grapesjs from 'grapesjs';
import initComponents from './components';
import initUniversalComponents from './universal-components';
import careerumComponents from './components-careerum';
import loadBlocks from './blocks-careerum';


export default grapesjs.plugins.add('gjs-blocks-careerum-en', (editor: any, opts: any = {}) => {
  const config = {
    category: 'Careerum EN',
    ...opts
  };

  const domc = editor.DomComponents;

  // Add components
  const addTypeFromClassName = initComponents(editor, config);
  addTypeFromClassName(careerumComponents);

  initUniversalComponents(editor, config);

  // Add blocks
  loadBlocks(editor, config);

  // Define new Trait type - link onclick
  const onclickLinkType = 'onclick';
  editor.TraitManager.addType(onclickLinkType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(onclickLinkType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[onclickLinkType] = newVal;
    }
  });

  const onclickLinkComponents = [
    'link',
  ];
  let defaultAttributesOnclockLink: any = {
    required: false
  };
  defaultAttributesOnclockLink[onclickLinkType] = '';

  onclickLinkComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Onclick',
              name: onclickLinkType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesOnclockLink
        }
      }

    })
  });
  

  // Define new Trait type - timer
  const timerType = 'data-timer';
  editor.TraitManager.addType(timerType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(timerType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[timerType] = newVal;
    }
  });

  const timerComponents = [
    'careerum-en-header',
    'careerum-en-header-tablet',
    'careerum-en-header-mobile',
    'careerum-en-bootcamp-timer-banner',
    'careerum-en-bootcamp-timer-banner-tablet',
    'careerum-en-bootcamp-timer-banner-mobile',
    'careerum-en-bootcamp-bottom-banner',
    'careerum-en-bootcamp-bottom-banner-tablet',
    'careerum-en-bootcamp-bottom-banner-mobile',
  ];
  let defaultAttributesTimer: any = {
    required: false
  };
  defaultAttributesTimer[timerType] = '';

  timerComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Статичная дата',
              name: 'data-static',
              type: 'checkbox',
              valueTrue: 'yes',
              valueFalse: 'no',
            },
            {
              label: 'Дата и время окончания отсчета (08/16/2022 10:00 GMT+0300)',
              name: 'data-timer-end',
              type: 'text',
            },
            {
              label: 'Кол-во часов (для нестатичной даты)',
              name: 'data-offset',
              type: 'text',
            },
            {
              label: 'Редирект по окончании',
              name: 'data-redirect',
              type: 'checkbox',
              valueTrue: 'yes',
              valueFalse: 'no',
            },
            {
              label: 'URL редиректа',
              name: 'data-url',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTimer
        }
      }

    })
  });

  // Define new Trait type - EN club popup tarifs
  const offerType = 'data-offer-id';
  editor.TraitManager.addType(offerType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(offerType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[offerType] = newVal;
    }
  });

  const offerComponents = [
    'careerum-bootcamp-popup-wrapper'
  ];
  let defaultAttributesOffer: any = {
    required: false
  };
  defaultAttributesOffer[offerType] = '';

  offerComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Offer ID',
              name: offerType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesOffer
        }
      }

    })
  });

  // Define new Trait type - EN club video ID
  const videoType = 'data-video-id';
  editor.TraitManager.addType(videoType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(videoType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[videoType] = newVal;
    }
  });

  const videoComponents = [
    'careerum-en-bootcamp-main',
  ];
  let defaultAttributesVideo: any = {
    required: false
  };
  defaultAttributesVideo[videoType] = '';

  videoComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Vimeo Video ID',
              name: videoType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesVideo
        }
      }

    })
  });

  // Define new Trait type - EN club video ID
  const elOffsetType = 'data-offset';
  editor.TraitManager.addType(elOffsetType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(elOffsetType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[elOffsetType] = newVal;
    }
  });

  const elOffsetComponents = [
    'tariffs-items-wrapper',
  ];
  let defaultAttributesElOffset: any = {
    required: false
  };
  defaultAttributesElOffset[elOffsetType] = '';

  elOffsetComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'S - Отступ сверху открытие',
              name: 'data-offset-open-top',
              type: 'number',
            },
            {
              label: 'S - Отступ снизу открытие',
              name: 'data-offset-open-bottom',
              type: 'number',
            },
            {
              label: 'S - Отступ сверху закрытие',
              name: 'data-offset-close-top',
              type: 'number',
            },
            {
              label: 'S - Отступ снизу закрытие',
              name: 'data-offset-close-bottom',
              type: 'number',
            },
          ],
          attributes: defaultAttributesElOffset
        }
      }

    })
  });

});
